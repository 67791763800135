/* Commented code will be useful in future */

body {
  background-image: url("./assets/images/Login.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 0 !important;
}

::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #e6e6e6;
}

:root {
  --toastify-color-info: #47a8f5;
  --toastify-color-success: #1eaf83;
  --toastify-color-warning: #ffa92b;
  --toastify-color-error: #F44336;
  --toastify-color-progress-light: linear-gradient(135deg, #1c75bc, #00aeef);
  --toastify-text-color-light: #414042;
  --toastify-color-light: #ffff;
  --toastify-close-bg-color: #000000;
  --color-black2: #414042;
  --color-gray1: #58595B;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

.Toastify__toast-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  gap: 25px;
}

.Toastify__toast-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 0;
  margin-left: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.Toastify__progress-bar--wrp {
  height: 2px;
}

.Toastify__toast--success {
  border-top: 0.15rem solid var(--toastify-color-success);
}
.Toastify__toast--success > .Toastify__toast-body > .Toastify__toast-icon {
  box-shadow: 
      0 0 0 2px rgba(52, 168, 83, 0.1), 
      0 0 0 7px rgba(52, 168, 83, 0.1),
      0 0 0 12px rgba(52, 168, 83, 0.1);
}

.Toastify__toast--error {
  border-top: 0.15rem solid var(--toastify-color-error);
}
.Toastify__toast--error > .Toastify__toast-body > .Toastify__toast-icon {
  background: var(--toastify-color-error);
  box-shadow: 
      0 0 0 2px rgba(234, 67, 53, 0.1), 
      0 0 0 7px rgba(234, 67, 53, 0.1),
      0 0 0 12px rgba(234, 67, 53, 0.1);
}

.Toastify__toast--warning {
  border-top: 0.15rem solid var(--toastify-color-warning);
}
.Toastify__toast--warning > .Toastify__toast-body > .Toastify__toast-icon {
  box-shadow: 
      0 0 0 2px rgba(255, 169, 43, 0.1),
      0 0 0 7px rgba(255, 169, 43, 0.1), 
      0 0 0 12px rgba(255, 169, 43, 0.1);
}

.Toastify__toast--info {
  border-top: 0.15rem solid var(--toastify-color-info);
}
.Toastify__toast--info > .Toastify__toast-body > .Toastify__toast-icon {
  box-shadow: 
      0 0 0 2px rgba(71, 168, 245, 0.1),
      0 0 0 7px rgba(71, 168, 245, 0.1), 
      0 0 0 12px rgba(71, 168, 245, 0.1);
}

@media only screen and (max-width: 1800px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 8px;
  }
}

.dashboard-carousel-button-bar {
  margin: 2.357rem 2.357rem 0 2.357rem;
  padding: 0;
  width: 88%;
  text-align: center;
}

.dashboard-carousel-button-bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 0.571rem;
  padding: 0;
}

.dashboard-carousel-button-bar li button {
  border: none;
  background: var(--color-gray1);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 0.571rem;
  width: 0.571rem;
  border-radius: 50%;
  opacity: 20%;
}

.dashboard-carousel-button-bar li.slick-active button {
  background:  var(--toastify-color-progress-light);
  opacity: 100%;
}
.Marquee {
  box-sizing: border-box;
  padding: 1rem;
  color: white;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.Marquee-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Marquee-content-ltr {
  animation: marquee 30s forwards linear infinite;
}
.Marquee-content-rtl {
  animation: marquee 30s reverse linear infinite;
}
.Marquee-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
@keyframes marquee {
  to {
    transform: translateY(calc(-50% - 0.5rem));
  }
}

#webpack-dev-server-client-overlay {
  display: none;
}
